<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row mb-2">
          <div class="col-sm-6">
          </div>
          <div class="col-sm-6">
            <div class="search-box mr-2 mb-2  text-sm-right">
              <div class="position-relative">
                <input type="text" class="form-control" placeholder="Search..." />
                <i class="bx bx-search-alt search-icon"></i>
              </div>
            </div>
          </div>
          <!-- end col-->
        </div>
        <div class="table-responsive">
          <table class="table project-list-table table-nowrap table-centered table-borderless">
            <thead>
              <tr>
                <th scope="col" width="12%">Status</th>
                <th scope="col" width="35%">Descrição</th>
                <th scope="col" width="10%">Eventos</th>
                <th scope="col" width="10%">Faturado</th>
                <th scope="col" width="12%">Vencimento</th>
                <th scope="col" width="15%">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="invoices.length === 0">
                <td colspan="7" class="text-center p-5">
                  Nenhuma fatura encontrada.
                </td>
              </tr>
              <tr v-for="(invoice, i) in invoices" :key="i" class="link">
                <td @click="showInvoice(invoice.id)">
                  <span class="badge badge-pill font-size-12" :class="{
                      'badge-soft-warning': `${parseInt(invoice.status)}` === '1',
                      'badge-soft-success': `${parseInt(invoice.status)}` === '2',
                      'badge-soft-dark': `${parseInt(invoice.status)}` === '3',
                    }">{{ statusLabel[invoice.status] }}</span>
                </td>
                <td @click="showInvoice(invoice.id)">
                  <h5 class="text-truncate font-size-14">
                      <a href="javascript: void(0);" class="text-dark">{{ invoice.description }}</a>
                  </h5>
                </td>
                <td @click="showInvoice(invoice.id)">{{ invoice.qty_events }} Evento(s)</td>
                <td @click="showInvoice(invoice.id)">{{ $moment(invoice.invoice_date).format('DD/MM/YYYY') }}</td>
                <td @click="showInvoice(invoice.id)">{{ $moment(invoice.invoice_due_date).format('DD/MM/YYYY') }}</td>
                <td @click="showInvoice(invoice.id)">{{ invoice.invoice_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" v-if="invoices.length > 0">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader
  },
  name: 'invoices',
  page: {
    title: 'Minhas Faturas',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      submitted: false,
      params: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      statusLabel: ['', 'Pagamento Pendente', 'Pago', 'Cancelada'],
      title: 'Minhas Faturas',
      items: [{
        text: 'Minhas Faturas',
        href: '/events'
      },
      {
        text: 'Listagem',
        active: true
      }
      ]
    }
  },
  mounted () {
    this.updateList()
    this.totalRows = this.pagination.total
    this.currentPage = this.pagination.current_page
    this.loading = false
  },
  computed: {
    ...mapState('invoices', ['invoices', 'pagination']),
    rows () {
      return this.pagination.total
    }
  },
  methods: {
    ...mapActions('invoices', {
      getAll: 'getAll'
    }),
    showInvoice (id) {
      this.$router.push({
        name: 'invoice-show',
        params: {
          id: id
        }
      })
    },
    getReport (filter) {
      let params = {
        include: 'company,items',
        search: this.search || null,
        limit: this.perPage,
        page: this.currentPage,
        status: filter.status || null
      }
      this.params = params
      let loeader = this.$loading.show()
      this.getAll(this.params).then((res) => {
        // this.participants = res.data
        this.totalRows = this.pagination.total
        this.currentPage = this.pagination.current_page
        loeader.hide()
      }).catch(error => {
        console.log(error)
        loeader.hide()
      })
    },
    doSearch () {
      this.params = { ...this.params, search: this.search }
      this.getReport(this.params)
    },
    updateList () {
      this.getReport(this.params)
    }
  }
}
</script>

<style scoped>
.table-responsive { min-height: 600px;}
</style>
